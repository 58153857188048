// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwBaseSublocation from 'viewmodel/AwBaseSublocationViewModel';
import AwTileCanvas from 'viewmodel/AwTileCanvasViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [
        "js/aw-base-sublocation.directive",
        "js/aw-tile-canvas.directive"
    ],
    "actions": {
        "reveal": {
            "actionType": "TcSoaService",
            "serviceName": "Internal-AWS2-2022-12-DataManagement",
            "method": "getCurrentUserGateway3",
            "inputData": {},
            "outputData": {
                "tileGroups": "tileGroups"
            },
            "actionMessages": {
                "failure": [
                    {
                        "message": "accessingGatewayFailed"
                    }
                ]
            }
        },
        "updateTile": {
            "actionType": "TcSoaService",
            "serviceName": "Internal-AWS2-2018-05-DataManagement",
            "method": "updateTiles",
            "inputData": {
                "tiles": "{{data.eventData.tiles}}",
                "groupNames": "{{data.eventData.groupNames}}"
            },
            "outputData": {
                "tileGroups": "tileGroups"
            },
            "actionMessages": {
                "failure": [
                    {
                        "message": "updateTileFailed"
                    }
                ]
            }
        },
        "unpinTile": {
            "actionType": "TcSoaService",
            "serviceName": "Internal-AWS2-2018-05-DataManagement",
            "method": "unpinObjects",
            "inputData": {
                "uidsToUnpin": [
                    "{{data.eventData.relUID}}"
                ],
                "returnGateway": true
            },
            "outputData": {
                "tileGroups": "tileGroups"
            },
            "actionMessages": {
                "failure": [
                    {
                        "message": "unPinFailed"
                    }
                ]
            }
        }
    },
    "messages": {
        "accessingGatewayFailed": {
            "messageType": "ERROR",
            "messageText": "{{i18n.pinObjectFailedNoGateway}}"
        },
        "unPinFailed": {
            "messageType": "ERROR",
            "messageText": "{{i18n.unpinTileFailed}}"
        },
        "updateTileFailed": {
            "messageType": "ERROR",
            "messageText": "{{i18n.tileUpdateFailedMsg}}"
        }
    },
    "onEvent": [
        {
            "eventId": "gateway.updateTile",
            "action": "updateTile",
            "cacheEventData": true
        },
        {
            "eventId": "gateway.unpinTile",
            "action": "unpinTile",
            "cacheEventData": true
        }
    ],
    "i18n": {
        "pinObjectFailedNoGateway": [
            "GatewayMessages"
        ],
        "unpinTileFailed": [
            "GatewayMessages"
        ],
        "tileUpdateFailedMsg": [
            "GatewayMessages"
        ]
    },
    "lifecycleHooks": {
        "onMount": "reveal"
    },
    "_viewModelId": "Gateway",
    "_uniqueViewModelId": "Gateway",
    "ctx": {}
};

    /*
    <aw-base-sublocation provider="data._sublocation">
    <aw-tile-canvas tile-groups="data.tileGroups"></aw-tile-canvas>
</aw-base-sublocation>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwBaseSublocation provider={AwParseService.instance( "data._sublocation" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        <AwTileCanvas dndHandler={props.viewModel.dndHandler} tileGroups={AwParseService.instance( "data.tileGroups" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </AwTileCanvas>
    </AwBaseSublocation>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;