// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwDefaultLocation from 'viewmodel/AwDefaultLocationViewModel';
    import { ExistWhen } from 'js/hocCollection';
const AwDefaultLocationExistWhen = ExistWhen(AwDefaultLocation);
AwDefaultLocationExistWhen.displayName = 'AwDefaultLocationExistWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/AwGatewayLocationService', () => import('js/AwGatewayLocationService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [],
    "lifecycleHooks": {
        "onMount": "initialize",
        "onUnmount": "cleanup"
    },
    "actions": {
        "initialize": {
            "actionType": "JSFunctionAsync",
            "method": "initializePage",
            "inputData": {},
            "outputData": {
                "headerTitle": "headerTitle"
            },
            "deps": "js/AwGatewayLocationService"
        },
        "cleanup": {
            "actionType": "JSFunction",
            "method": "cleanupPage",
            "inputData": {},
            "deps": "js/AwGatewayLocationService"
        }
    },
    "data": {
        "showsearchbox": {
            "dbValue": true,
            "type": "BOOLEAN",
            "isRequired": "false",
            "isEditable": "true"
        }
    },
    "_viewModelId": "AwGatewayLocation",
    "_uniqueViewModelId": "AwGatewayLocation",
    "ctx": {}
};

    /*
    <aw-default-location exist-when="data.headerTitle" sub-panel-context="{locationPanelStyle: 'aw-gateway-locationPanel',overriddenHeaderTitle: data.headerTitle, showsearchbox: data.showsearchbox}"></aw-default-location>
    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwDefaultLocationExistWhen subPanelContext={AwParseService.instance( "{locationPanelStyle: 'aw-gateway-locationPanel',overriddenHeaderTitle: data.headerTitle, showsearchbox: data.showsearchbox}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "data.headerTitle" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
    </AwDefaultLocationExistWhen>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;